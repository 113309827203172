// Copyright © 2021 Move Closer

import { AsyncComponent } from 'vue'

import { MachineState } from './QuestionForm.contracts'

/**
 * Registry that binds the `MachineState` with the applicable Vue component.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const questionFormMachineStateComponentRegistry: Record<MachineState, AsyncComponent> = {
  [MachineState.DataEntry]: () => import(
    /* webpackChunkName: "molecules/QuestionForm" */
    './partials/states/DataEntry.vue'
  ),
  [MachineState.Error]: () => import(
    /* webpackChunkName: "molecules/QuestionForm" */
    './partials/states/Error.vue'
  ),
  [MachineState.Success]: () => import(
    /* webpackChunkName: "molecules/QuestionForm" */
    './partials/states/Success.vue'
  )
}
