// Copyright © 2021 Move Closer

import { AbstractSelectControlOption } from '@movecloser/ui-core'
import { TranslateResult } from 'vue-i18n'

import { AnswerFormData } from '../../../repositories/questions'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export interface Agreement extends Pick<AbstractSelectControlOption, 'value'> {
  /**
   * @see AbstractSelectControlOption.label
   */
  label: TranslateResult

  /**
   * Determines whether the given agreement is required to be checked.
   */
  required: boolean
}

/**
 * Context that can be shared between the states.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export interface MachineContext {
  /**
   * Array of the error messages.
   */
  errors: string[] | null

  /**
   * Data entered by the User.
   */
  formData: AnswerFormData | null
}

/**
 * Available events that can toggle the transitions between the component's states.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export enum MachineEvent {
  /**
   * When the submitted form data has been accepted by the API.
   */
  SubmissionAccepted = 'submissionAccepted',

  /**
   * When the submitted form data contains some errors.
   */
  SubmissionRejected = 'submissionRejected',

  /**
   * When the User clicks the "try again" button.
   */
  TryAgain = 'tryAgain',

  /**
   * When the submitted form data are invalid
   */
  ValidationFailed = 'validationFailed',
}

/**
 * Payload expected be each of the machine's actions.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export type MachineEventData = Partial<MachineContext>

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export interface MachineEventObject extends MachineEventData {
  /**
   * The type of the event being fired.
   */
  type: MachineEvent
}

/**
 * Possible states that the component can be found in.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export enum MachineState {
  /**
   * When the form is being displayed and accepts new data entry.
   */
  DataEntry = 'dataEntry',

  /**
   * When the form has been submitted but contains some errors.
   */
  Error = 'error',

  /**
   * When the form has been successfully submitted.
   */
  Success = 'success',
}
